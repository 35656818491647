import React from 'react'

import Layout from '../components/layout'
import { FirstVisit } from '../components/FirstVisit'

export const FirstVisitPage = () => (
  <Layout seo={{ title: 'Your First Visit' }}>
    <FirstVisit />
  </Layout>
)

export default FirstVisitPage
