import React from 'react'

import { INTERNAL_URLS, EXTERNAL_URLS } from '../../config/urls'

import landingStyles from '../Landing/Landing.module.scss'
import styles from './FirstVisit.module.scss'

import { ServicesSectionImg } from '../Services/shared/ServicesSectionImg'
import { ServicesSectionText } from '../Services/shared/ServicesSectionText'
import { Question } from '../common/Question'

import ArrowRight from '../svg/ArrowRight'

import VGAImage from 'components/images/vga-street-view.png'

export class FirstVisit extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.page}>
          <div className={styles.pageContent}>
            <section className="mb-32">
              <div className={landingStyles.aboutSectionBorder} />
              <h2 className={styles.headerTitle}>
                Your First Visit
              </h2>
              <div className={styles.headerSubtitle}>
                We look forward to providing you with our best care.
                Don't forgot to bring your ID and insurance, and please arrive 15 minutes before your appointment time.
              </div>
            </section>

            <section className="flex flex-wrap w-full justify-between mb-10 md-mb-10">
              <div className="mb-8 md-pr-16 w-full md-w-1/2">
                <ServicesSectionImg
                  src={VGAImage}
                  alt="Valencia Gynecology Associates"
                  direction="left"
                  customStyles={{ container: 'w-full md-w-full mb-10' }}
                />
              </div>
              <div className="w-full md-w-1/2 md-pl-12">
                <ServicesSectionText title="We are excited to see you." className="md-w-full mb-20">
                  <div className="flex flex-col space-y-4">
                    <p>
                      We know that your first visit to a doctor can sometimes be stressful, but we are confident that you'll soon feel comfortable with our experienced team of professionals health care providers.
                    </p>
                    <p>
                      You'll be in good hands, but in case you have any questions or concerns before your appointment, don't hesitate to contact our office and we'd be happy to provide answers.
                    </p>
                    <p>
                      Below, you can also find a few of the frequently asked questions we often hear from new patients as well.
                    </p>
                  </div>
                </ServicesSectionText>
              </div>
            </section>
            <Question title="Are my medical records kept private and confidential?">
              <p>
                Your medical file is handled with the utmost respect for your privacy. Our staff is bound by strict confidentiality requirements as a condition of employment regarding your medical records. The staff at Valencia Gynecology Associates abide strictly by HIPPA guidelines.
              </p>
              <p>
                Our staff will not release the contents of your medical file without your consent.
              </p>
            </Question>
            <Question title="What insurance is accepted by the practice?">
              <p>
                We accept PPO and Medicare with secondary insurances. <b>Unfortuntately, we do not accept HMOs.</b>
              </p>
              <p>
                Please check with our office if you are unsure or have any questions.
              </p>
            </Question>
            <Question title="What are your acceptable means of payment?">
              <p>
                Bank check, personal check, cash or credit cards (MasterCard, VISA, AMEX) are acceptable means of payment.  You are expected to pay your copay at the time of your appointment.
              </p>
            </Question>
          </div>
        </div>
      </div>
    )
  }
}
